<template>
  <div>
    <advertisement-top-banner-text />
    <template v-if="$options.components['advertisement-loading']">
      <advertisement-loading v-if="isLoading" />
    </template>
    <div
      else
      :style="{ display: cartData.length != 0 ? '' : 'none' }"
      class="checkout"
    >
      <b-container>
        <b-row>
          <b-col md="8">
            <template v-if="!activatePaymentKey">
              <template v-if="$options.components['checkout-order-summary']">
                <checkout-order-summary
                  :loadingItem="loadingItem"
                  :cartData="$store.state['checkout'].cartData"
                />
              </template>
            </template>
            <template v-if="activatePaymentKey">
              <template v-if="$options.components['checkout-cart-payment']">
                <checkout-cart-payment />
              </template>
            </template>
          </b-col>

          <b-col md="4">
            <template
              v-if="
                (!activatePaymentKey && !selfPickSelected) || !this.selected
              "
            >
              <!-- delivery adderss -->
              <template v-if="$options.components['address-cart-address']">
                <address-cart-address :callApi="false" />
              </template>
              <!-- // -->
              <!-- coupon code -->
              <template v-if="$options.components['checkout-cart-coupon']">
                <checkout-cart-coupon :callApi="false" />
              </template>
              <!-- // -->
            </template>
            <template v-if="$options.components['checkout-price-card']">
              <checkout-price-card :activatePaymentKey="activatePaymentKey" />
            </template>
            <template v-if="this.allowPickupFromShop">
              <b-form-checkbox
                id="checkbox-selfpick"
                value="true"
                class="self-pickup"
                v-model="selected"
                @change="onClickCheckbox"
                rules="required"
              >
                Self Pickup From Shop Select pickup date and time
              </b-form-checkbox>
              <b-form-datepicker
                placeholder="Pickup date"
                v-if="selected"
                id="date-time"
                v-model="date"
                class="mb-2"
              ></b-form-datepicker>
              <div class="d-flex justify-content-between mt-2" v-if="selected">
                <b-form-select
                  class="time-picker"
                  v-model="selectedHour"
                  :options="hours"
                  @change="processForward"
                ></b-form-select>
                <b-form-select
                  class="time-picker"
                  v-model="selectedMinute"
                  :options="minutes"
                  @change="setTime"
                ></b-form-select>
                <b-form-select
                  class="time-picker"
                  v-model="selectedFormat"
                  :options="formats"
                  @change="setTime"
                ></b-form-select>
              </div>
            </template>
            <!-- <template
              v-if="$options.components['checkout-transaction-benefits']"
            >
              <checkout-transaction-benefits />
            </template> -->
            <!-- <template :activatePaymentKey="activatePaymentKey">
              <p id="cashback" class="text"></p>
            </template> -->
          </b-col>
        </b-row>
      </b-container>
      <b-container>
        <b-row class="mt-5 mb-3">
          <b-col class="text-center">
            <template v-if="!activatePaymentKey">
              <button
                @click="() => this.clearCart()"
                type="button"
                class="btn btn-outline-primary btn-lg mr-3 mb-2"
              >
                <span class="button-text"></span> Clear Cart
              </button>
              <button
                :disabled="!($store.state['checkout'].checkoutLoading == false)"
                @click="continuePayment"
                type="button"
                class="btn btn-primary btn-lg mr-3 mb-2"
              >
                <span class="button-text"></span> Continue
              </button>
            </template>
            <template v-if="activatePaymentKey">
              <template v-if="$store.state['checkout'].checkoutLoading">
                <b-spinner
                  v-for="i in 3"
                  :key="i"
                  class="mr-1"
                  small
                  label="Small Spinner"
                  type="grow"
                ></b-spinner>
              </template>

              <button
                v-else
                @click="placeOrderInit"
                type="button"
                class="btn btn-primary btn-lg"
              >
                <span class="button-text"></span> Place Order
              </button>
            </template>
          </b-col>
        </b-row>
      </b-container>
      <b-modal
        id="bv-add-address"
        size="lg"
        :hide-footer="true"
        :hide-header="true"
        :no-close-on-esc="true"
        :no-close-on-backdrop="true"
        centered
        header-class="my-class"
        body-class="p-0"
      >
        <template v-if="$options.components['address-add-address-modal']">
          <address-add-address-modal />
        </template>
      </b-modal>
    </div>
    <div>
      <div
        :style="{ display: cartData.length == 0 ? '' : 'none' }"
        class="text-center checkout-empty"
      >
        <b-img inline src="img/icon-empty-cart.png" fluid></b-img>
        <h3 class="font-weight-bold mb-4 mt-4">Opps , cart is empty!</h3>
        <b-button @click="$router.push('/')" class="mb-2 w-auto button-color"
          >Continue Shopping</b-button
        >
      </div>
    </div>

    <VueCashfree
      :initialiseKey="initialiseKey"
      @onSuccess="onSuccess"
      @onFailure="onFailure"
      :orderToken="orderToken"
      :env="cashfreeEnv"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Vue from "vue";
import VueCashfree from "vue-cashfree-sdk";
export default {
  name: "checkout",
  components: {
    VueCashfree,
  },
  computed: {
    ...mapState({
      allowPickupFromShop: (state) => state.checkout.allowPickupFromShop,
      checkoutData: (state) => state.checkout.checkoutData,
      partnerInfo: (state) => state.checkout.partnerInfo,
      cartData: (state) => state.checkout.cartData,
      defaultAddress: (state) => state.address.defaultAddress,
      userInfo: (state) => state.auth.userInfo,
      paymentMethodClick: (state) => state.checkout.paymentMethodClick,
      createdOrderData: (state) => state.checkout.createdOrderData,
    }),
  },
  data() {
    return {
      isLoading: false,
      cashfreeEnv: process.env.VUE_APP_CASHFREE_ENVIRONMENT,
      selfPickSelected: false,
      loadingItem: false,
      activatePaymentKey: false,
      initialiseKey: false,
      orderToken: "",
      time: "",
      date: "",
      selected: false,
      selfPickup: false,
      pickup: "",
      selectedHour: null,
      hours: [
        { value: null, text: "Hour" },
        { value: "12", text: "12" },
        { value: "11", text: "11" },
        { value: "10", text: "10" },
        { value: "09", text: "09" },
        { value: "08", text: "08" },
        { value: "07", text: "07" },
        { value: "06", text: "06" },
        { value: "05", text: "05" },
        { value: "04", text: "04" },
        { value: "03", text: "03" },
        { value: "02", text: "02" },
        { value: "01", text: "01" },
      ],
      selectedMinute: null,
      minutes: [
        { value: null, text: "Min" },
        { value: "00", text: "00" },
        { value: "15", text: "15" },
        { value: "30", text: "30" },
        { value: "45", text: "45" },
      ],
      selectedFormat: "AM",
      formats: [
        { value: "AM", text: "AM" },
        { value: "PM", text: "PM" },
      ],
      selectedTime: "",
      newHour: "",
      activeContinue: true,
      readyToPay: false,
    };
  },
  created() {
    this.$store.state["checkout"].paymentMethodClick = "CASHFREE";
    if (!this.$store.state["auth"].isLoggedin) {
      this.$router.push({ name: "login" });
    }
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  watch: {
    date() {
      this.processForward();
    },
  },
  async mounted() {
    this.loadingItem = true;
    await this.fetchCart();
    //this.cashBackAccount();
    try {
      if (this.cartData.length != 0) {
        await this.$store.dispatch("address/fetchDeliveryAddress");
        await this.$store.dispatch("checkout/fetchCouponList");
        await this.$store.dispatch("checkout/checkoutFinal");
        await this.$store.dispatch(
          "checkout/fetchPartnerInfo",
          this.cartData[0].provider
        );
      }
    } catch {
      console.log("");
    }
    this.loadingItem = false;
  },
  methods: {
    async onClickCheckbox() {
      if (this.selected) {
        this.selfPickSelected = true;
      }
      this.$store.commit("checkout/setPickupStatus", {
        selected: this.selected,
      });
      await this.$store.dispatch("checkout/checkoutFinal");
    },
    processForward() {
      if (this.selectedMinute !== null) {
        this.setTime();
        this.readyToPay = true;
      }
    },
    async setTime() {
      this.newHour = this.selectedHour;
      if (this.selectedFormat === "PM" && this.selectedHour !== "12") {
        this.newHour = Number(this.newHour) + 12;
        this.newHour = this.newHour.toString();
      }
      this.selectedTime = `${this.newHour}:${this.selectedMinute}:00`;
      this.pickup = `${this.date}T${this.selectedTime}`;
      this.$store.commit("checkout/setPickupStatus", {
        pickup: this.pickup,
        selected: this.selected,
      });
      await this.$store.dispatch("checkout/checkoutFinal");
      this.readyToPay = true;
    },
    onFailure(err) {
      console.log(err);
      this.$swal({
        group: "item",
        title:
          "Sorry..we are not able to place your order currently , if money is deducted it will be refund out in between 7 working days.",
        text: "",
        type: "error",
      });
      this.$router.push({ path: "/" });
    },
    // async cashBackAccount() {
    //   try {
    //     await this.$store
    //       .dispatch("checkout/getAccountDetails", this.userInfo._id)
    //       .then((res) => {
    //         if (res.data.data.status === "SUCCESS") {
    //           console.log(res);
    //         }
    //       });
    //   } catch (e) {
    //     document.getElementById("cashback").innerText =
    //       "To receive cashbacks please add your bank details under cashback accounts.";
    //   }
    // },
    onSuccess(res) {
      console.log(res);
      this.placeOrderFinal();
    },
    async clearCart() {
      await this.$store.dispatch("checkout/clearCart");
    },
    async placeOrderInit() {
      if (this.paymentMethodClick == "COD") {
        this.createOrder();
      }
      if (this.paymentMethodClick == "CASHFREE") {
        this.$store.state["checkout"].checkoutLoading = true;
        await this.$store
          .dispatch("checkout/createOrderAction")
          .then(async (res) => {
            console.log("order id", res);
            if (res.status === 200) {
              this.initiateCashfree();
            } else {
              this.$swal({
                type: "error",
                title: "Oops...",
                text: "Order Creation Failed",
              });
              // this.$router.push({ path: "/" });
              await this.$store.dispatch("checkout/checkoutFinal");
            }
          })
          .catch((err) => {
            console.log(err, "");
          });
      }
    },
    async initiateCashfree() {
      if (this.checkoutData?.payment?.signatureWeb) {
        this.orderToken = this.checkoutData?.payment?.signatureWeb;
        this.initialiseKey = false;
        setTimeout(() => {
          this.initialiseKey = true;
        }, 1);
      } else {
        this.initialiseKey = false;
      }
    },
    async createOrder() {
      this.$store.state["checkout"].checkoutLoading = true;
      await this.$store
        .dispatch("checkout/createOrderAction")
        .then(async (res) => {
          if (res.status === 200) {
            this.$store.state["checkout"].checkoutLoading = false;
            await this.$store.dispatch("checkout/clearCart");
            this.$store.state["checkout"].cartData = [];
            this.$router.push({
              name: "order-success",
              params: {
                orderId: res.data?.response?.orderId,
              },
            });
          } else {
            this.$swal({
              type: "error",
              title: "Oops...",
              text: "Order Creation Failed",
            });
            // this.$router.push({ path: "/" });
            await this.$store.dispatch("checkout/checkoutFinal");
          }
        })
        .catch((err) => {
          this.$store.state["checkout"].checkoutLoading = false;
          console.log("err", err);
        });
    },
    async placeOrderFinal() {
      let payload = {
        customerDetails: {
          orderAmount: this.checkoutData.totalPrice,
          customerName: this.userInfo.authentication.userName,
          customerPhone: this.userInfo.authentication.phone,
          customerEmail: this.userInfo.authentication.email,
        },
        orderDetails: {
          paymentType: this.checkoutData.payment.type,
          deliveryAddress: {
            address: this.defaultAddress,
            loc: this.defaultAddress.location,
          },
          items: this.checkoutData.items ? this.checkoutData.items : [],
        },
        createdAt: this.checkoutData.createdAt,
        payments: this.checkoutData.payments,
        payment: this.checkoutData.payment,
        orderHash: this.checkoutData.hash,
        totalPriceBeforeTax: this.checkoutData.totalPriceBeforeTax,
        orderId: this.createdOrderData?.orderId,
      };
      await this.$store
        .dispatch("checkout/validateOrderAction", payload)
        .then(async (res) => {
          console.log("order id", res);
          this.$store.state["checkout"].checkoutLoading = false;
          this.$router.push({
            name: "order-success",
            params: {
              orderId: res.data?.response?.orderId,
            },
          });
        })
        .catch((err) => {
          this.$store.state["checkout"].checkoutLoading = false;
          console.log("err", err);
        });
    },
    showCustomToast() {
      Vue.toasted.show("select Date and Time", {
        type: "info",
        position: "bottom-center",
        theme: "toasted-primary",
        singleton: true,
        duration: 3000,
      });
    },
    continuePayment() {
      if (this.selected && this.date != "" && this.readyToPay) {
        this.activatePaymentKey = true;
      } else if (this.selected) {
        if (
          this.date == "" ||
          this.selectedMinute == null ||
          this.selectedHour == null
        ) {
          this.showCustomToast();
        }
      } else {
        this.activatePaymentKey = true;
      }
    },
    async fetchCart() {
      await this.$store.dispatch("checkout/fetchCartData", {
        shipFrom: "IN",
        shipTo: "IN",
        country: "IN",
      });
    },
  },
};
</script>
<style scoped>
.checkout {
  padding-top: 15px;
  margin-bottom: 15px;
}

.self-pickup {
  font-style: normal;
  padding: 16px;
  margin-top: 10px;
  width: 300px;
  margin-left: 10px;
  color: hsl(0, 6%, 3%);
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
}
.color-transaction {
  background: linear-gradient(#ff1934, #ff4d35, #ff7f37);
}
.button-color {
  background-color: #faa819;
}
::v-deep #date-time__dialog_ {
  z-index: 1;
}
.text {
  font-style: normal;
  color: rgb(240, 60, 60);
  padding: 18px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.btn-outline-primary {
  width: 180px;
  height: 45px;
}

.btn-primary {
  width: 180px;
  height: 45px;
  border-radius: 3px;
}

.btn-outline-primary:hover {
  width: 180px;
  height: 45px;
}

.button-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #faa819;
}

.button-text :hover {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #fff;
}

.checkout-empty {
  margin-top: 35px;
}
</style>
